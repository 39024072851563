<template>
    
    <div class="fixed top-0 mx-auto z-40 h-11 bg-base-gray/[.9] backdrop-blur-sm w-full">
        <header
            :class="[
                'flex flex-row justify-between mx-auto h-full w-full max-w-[1300px]',
            ]"
        >
            <a
                :href="evp.isEmbeddedMode ? route('booking-institutions') : route('home')"
                :class="[
                    'h-11 space-x-1 pr-2 md:pr-4 pl-0.5 shadow-none !rounded-none',
                    currentRoute === 'home'
                        ? 'button-transparent hover:shadow-primary-2xl'
                        : 'button-primary active:scale-100 hover:shadow-primary-2xl'
                ]"
            >
                <template v-if="evp.isErgoApp">
                    <svg
                        class="fill-current h-7 w-7 ml-1.5 md:ml-2 md:-mr-1.5"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 122.88 112.07"
                        style="enable-background:new 0 0 122.88 112.07"
                        xml:space="preserve"
                    ><g><path style="fill-rule:evenodd;clip-rule:evenodd;" d="M61.44,0L0,60.18l14.99,7.87L61.04,19.7l46.85,48.36l14.99-7.87L61.44,0L61.44,0z M18.26,69.63L18.26,69.63 L61.5,26.38l43.11,43.25h0v0v42.43H73.12V82.09H49.49v29.97H18.26V69.63L18.26,69.63L18.26,69.63z" /></g></svg>
                </template>
                <template v-else>
                    <svg
                        class="fill-current h-10 w-10 self-start"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewPort="0 0 40 40"
                    ><path d="M21.9828,19.2963 C24.2237,19.2963 26.0509,17.4863 26.0509,15.2281 C26.0509,12.9872 24.2237,11.16 21.9828,11.16 C19.7418,11.16 17.9146,12.97 17.9146,15.2281 C17.9146,17.4691 19.7418,19.2963 21.9828,19.2963 Z"></path><path d="M29.826,25.8123 C29.464,24.8125 27.723,21.1408 25.8613,20.8822 C23.9996,20.6236 19.9832,20.6236 18.1215,20.8822 C16.2598,21.1408 14.5187,24.8125 14.1567,25.8123 C13.7947,26.8121 13.1914,30.2941 13.1914,30.2941 L15.5358,31.2249 C15.5358,31.2249 15.6737,30.0355 15.8978,28.7599 C16.1219,27.4843 17.294,25.2434 17.9318,24.7952 C18.0008,25.2951 17.7767,29.8459 17.6905,31.6731 C19.9314,33.2073 24.4478,33.1728 26.3095,31.8972 C26.2233,30.07 25.9992,25.2951 26.0682,24.7952 C26.6887,25.2606 27.8781,27.4843 28.1022,28.7599 C28.3263,30.0355 28.4642,31.2249 28.4642,31.2249 L30.8086,30.2941 C30.7914,30.3113 30.188,26.8121 29.826,25.8123 M23.2928,25.9502 C23.2928,25.9502 21.4139,24.8814 21.4139,23.692 C21.4139,23.1576 21.8621,22.7094 22.3965,22.7094 C22.7929,22.7094 23.1549,22.9508 23.2928,23.3127 C23.448,22.968 23.7927,22.7094 24.1892,22.7094 C24.7236,22.7094 25.1718,23.1576 25.1718,23.692 C25.1718,24.8814 23.2928,25.9502 23.2928,25.9502 Z"></path></svg>
                    <span class="self-center pt-0.5 px-px hidden md:block">eVeselībasPunkts</span>
                </template>
            </a>

            <template v-if="evp.isTestEnv">
                <div class="text-gray-600 border-2 border-gray-600 flex items-center justify-center font-extrabold text-sm whitespace-nowrap h-9 mt-1 px-4 absolute left-1/2 translate-x-[-50%] pointer-events-none">
                    {{ $t('global.TestMode') }}
                </div>
            </template>

            <div class="flex h-11 flex-row-reverse md:relative flex-wrap overflow-hidden md:overflow-visible">
                <button class="button-transparent !rounded-none h-11 w-11" :title="$t('global.Language')" @click="languageDropdownVisible = !languageDropdownVisible">
                    <svg
                        class="h-8 w-8 fill-current"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 24 24"
                        style="enable-background:new 0 0 24 24;"
                        xml:space="preserve"
                    ><path d="M12.1,3.9C7.6,3.9,4,7.5,4,12s3.6,8.1,8.1,8.1c4.5,0,8.1-3.6,8.1-8.1S16.6,3.9,12.1,3.9z M17.7,8.8h-2.4 c-0.3-1-0.6-2-1.1-2.9C15.7,6.4,16.9,7.4,17.7,8.8z M12.1,5.6c0.7,1,1.2,2,1.5,3.2h-3.1C10.9,7.6,11.4,6.5,12.1,5.6z M5.9,13.6 c-0.1-0.5-0.2-1.1-0.2-1.6s0.1-1.1,0.2-1.6h2.7c-0.1,0.5-0.1,1.1-0.1,1.6s0,1.1,0.1,1.6H5.9z M6.5,15.2h2.4c0.3,1,0.6,2,1.1,2.9 C8.5,17.6,7.3,16.6,6.5,15.2z M8.9,8.8H6.5c0.8-1.3,2-2.4,3.5-2.9C9.5,6.8,9.2,7.8,8.9,8.8z M12.1,18.4c-0.7-1-1.2-2-1.5-3.2h3.1 C13.3,16.4,12.8,17.5,12.1,18.4z M14,13.6h-3.8c-0.1-0.5-0.1-1.1-0.1-1.6s0.1-1.1,0.1-1.6H14c0.1,0.5,0.1,1.1,0.1,1.6 S14.1,13.1,14,13.6z M14.2,18.1c0.5-0.9,0.9-1.9,1.1-2.9h2.4C16.9,16.6,15.7,17.6,14.2,18.1z M15.6,13.6c0.1-0.5,0.1-1.1,0.1-1.6 s0-1.1-0.1-1.6h2.7c0.1,0.5,0.2,1.1,0.2,1.6s-0.1,1.1-0.2,1.6H15.6z" /></svg>
                </button>

                <div v-if="languageDropdownVisible" ref="languageDropdown" class="bg-neutral-700/50 shadow-sm absolute w-full md:w-72 right-0 top-11 z-50 px-2 pt-2 pb-1">
                    <ul>
                        <li v-for="(langKey, locale) in evp.locales" :key="locale" class="flex h-11 items-center justify-center w-full bg-white mb-1">
                            <span v-if="evp.currentLocale === locale">
                                {{ $t('global.' + langKey) }}
                            </span>
                            <a
                                v-else
                                :onclick="`window.location.href = window.location.href.replace(/\\/${evp.currentLocale}(\\/|$)/, '/${locale}$1')`"
                                href="javascript:"
                                class="flex justify-center items-center h-full w-full no-underline text-primary hover:bg-primary-dark hover:text-white"
                            >
                                {{ $t('global.' + langKey) }} ({{ locale === 'lv' ? 'Latviešu' : (locale === 'en' ? 'English' : (locale === 'ru' ? 'Русский' : '')) }})
                            </a>
                        </li>
                    </ul>
                </div>

                <template v-if="! evp.isEmbeddedMode">
                    <button
                        @click="callOperatorDropdownVisible = !callOperatorDropdownVisible"
                        :class="[
                            'button-transparent !rounded-none h-11 w-11',
                            currentRoute === 'home' ? 'hidden md:flex' : '',
                        ]"
                        :title="$t('global.Call')"
                    >
                        <svg class="h-7 w-7 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none" /><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" /></svg>
                    </button>

                    <div v-if="callOperatorDropdownVisible" ref="callOperatorDropdown" class="bg-neutral-700/50 shadow-sm absolute w-full md:w-72 right-0 top-11 z-50 px-2 pt-2 pb-1">
                        <div class="flex h-14 items-center justify-center w-full bg-white mb-1">
                            <a :href="evp.isDesktopBrowser ? route('help-article', ['phone-call-cost']) : 'tel:8998'" class="flex justify-center items-center h-full w-full no-underline text-primary hover:bg-primary-dark hover:text-white py-2">
                                <div>
                                    <p>{{ $t('global.Call8998') }}</p>
                                    <p class="text-xs text-center">{{ $t('global.CostsApply') }}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </template>

                <template v-if="currentRoute !== 'home'">
                    <template v-if="evp.user">
                        <a class="button-transparent !rounded-none h-11 w-11" :href="route('favorite-specialists')" :title="$t('global.MyDoctorsTitle')">
                            <svg
                                class="h-6 w-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 122.88 109.57"
                                style="enable-background:new 0 0 122.88 109.57"
                                xml:space="preserve"
                            ><g><path d="M65.46,19.57c-0.68,0.72-1.36,1.45-2.2,2.32l-2.31,2.41l-2.4-2.33c-0.71-0.69-1.43-1.4-2.13-2.09 c-7.42-7.3-13.01-12.8-24.52-12.95c-0.45-0.01-0.93,0-1.43,0.02c-6.44,0.23-12.38,2.6-16.72,6.65c-4.28,4-7.01,9.67-7.1,16.57 c-0.01,0.43,0,0.88,0.02,1.37c0.69,19.27,19.13,36.08,34.42,50.01c2.95,2.69,5.78,5.27,8.49,7.88l11.26,10.85l14.15-14.04 c2.28-2.26,4.86-4.73,7.62-7.37c4.69-4.5,9.91-9.49,14.77-14.52c3.49-3.61,6.8-7.24,9.61-10.73c2.76-3.42,5.02-6.67,6.47-9.57 c2.38-4.76,3.13-9.52,2.62-13.97c-0.5-4.39-2.23-8.49-4.82-11.99c-2.63-3.55-6.13-6.49-10.14-8.5C96.5,7.29,91.21,6.2,85.8,6.82 C76.47,7.9,71.5,13.17,65.46,19.57L65.46,19.57z M60.77,14.85C67.67,7.54,73.4,1.55,85.04,0.22c6.72-0.77,13.3,0.57,19.03,3.45 c4.95,2.48,9.27,6.1,12.51,10.47c3.27,4.42,5.46,9.61,6.1,15.19c0.65,5.66-0.29,11.69-3.3,17.69c-1.7,3.39-4.22,7.03-7.23,10.76 c-2.95,3.66-6.39,7.44-10,11.17C97.2,74.08,91.94,79.12,87.2,83.66c-2.77,2.65-5.36,5.13-7.54,7.29L63.2,107.28l-2.31,2.29 l-2.34-2.25l-13.6-13.1c-2.49-2.39-5.37-5.02-8.36-7.75C20.38,71.68,0.81,53.85,0.02,31.77C0,31.23,0,30.67,0,30.09 c0.12-8.86,3.66-16.18,9.21-21.36c5.5-5.13,12.97-8.13,21.01-8.42c0.55-0.02,1.13-0.03,1.74-0.02C46,0.48,52.42,6.63,60.77,14.85 L60.77,14.85z" /></g></svg>
                        </a>

                        <button @click="profileDropdownVisible = !profileDropdownVisible" class="button-transparent !rounded-none h-11 px-1">
                            <div
                                :class="[
                                    'h-9 w-9 overflow-hidden rounded-full border-2',
                                    evp.user.isVerified ? 'border-[#36b37e]' : '',
                                ]"
                            >
                                <img src="/images/chrome/default-avatar.jpg" alt="" />
                            </div>
                            <div class="hidden sm:block ml-2 mr-0.5">
                                {{ evp.user.firstName }} {{ evp.user.lastName }}
                            </div>
                        </button>

                        <div v-if="profileDropdownVisible" ref="profileDropdown" class="bg-neutral-700/50 shadow-sm absolute w-full md:w-72 right-0 top-11 z-50 px-2 pt-2 pb-1">
                            <ul>
                                <li class="flex h-11 items-center justify-center w-full bg-white mb-1">
                                    <a
                                        :target="evp.isEmbeddedMode ? '_blank' : '_self'"
                                        :href="route('profile')"
                                        class="flex justify-center items-center h-full w-full no-underline text-primary hover:bg-primary-dark hover:text-white"
                                    >
                                        {{ $t('global.ViewProfileButton') }}
                                    </a>
                                </li>
                                <li class="flex h-11 items-center justify-center w-full bg-white mb-1">
                                    <a :href="route('logout')" class="flex justify-center items-center h-full w-full no-underline text-primary hover:bg-primary-dark hover:text-white">
                                        {{ $t('global.Logout') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template v-else>
                        <a class="button-transparent !rounded-none h-11 px-3" :href="route('login')">
                            <svg
                                class="fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            ><path d="M0 0h24v24H0z" fill="none" /><path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" /></svg>
                            <span class="hidden sm:inline ml-1.5">
                                {{ $t('global.Login') }}
                            </span>
                        </a>
                    </template>
                </template>

            </div>
        </header>
    </div>

    <div class="w-full mx-auto max-w-[1300px]">
        <div class="h-12 w-full"></div>

        <div class="notifications mb-5">
            <alert v-if="evp.initialMessages.warning" type="warning">
                {{ evp.initialMessages.warning }}
            </alert>

            <template v-if="evp.initialMessages.errors">
                <alert v-for="(errorMessage, index) in evp.initialMessages.errors" :key="index" type="error">
                    {{ errorMessage }}
                </alert>
            </template>

            <alert v-if="evp.initialMessages.success" type="success">
                {{ evp.initialMessages.success }}
            </alert>
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    import { route } from 'ziggy'
    import { onClickOutside } from '@vueuse/core'
    import Alert from '@/components/Alert.vue'

    const evp = window.evp
    const currentRoute = route().current()


    const languageDropdownVisible = ref(false)
    const languageDropdown = ref(null)

    onClickOutside(languageDropdown, () => {
        languageDropdownVisible.value = false
    })
    

    const profileDropdownVisible = ref(false)
    const profileDropdown = ref(null)

    onClickOutside(profileDropdown, () => {
        profileDropdownVisible.value = false
    })
    

    const callOperatorDropdownVisible = ref(false)
    const callOperatorDropdown = ref(null)

    onClickOutside(callOperatorDropdown, () => {
        callOperatorDropdownVisible.value = false
    })

</script>

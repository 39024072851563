import { i18nVue } from 'laravel-vue-i18n'


async function resolveTranslation(lang) {
    const langs = import.meta.glob('../../../lang/*.json')

    return await langs[`../../../lang/php_${lang}.json`]()
}


export async function loadTranslations(app) {
    const translationsPromise = resolveTranslation(window.evp.currentLocale)

    await translationsPromise

    const i18nVueOptions = {
        lang: window.evp.currentLocale,
        fallbackLang: 'lv',
        fallbackMissingTranslations: true,
        resolve: () => translationsPromise,
    }

    app.use(i18nVue, i18nVueOptions)
}
